<template>
  <div class="header">
    <div class="logo-container">
      <img alt="Vue logo" src="./assets/Adnosaur.png" class="logoImg">
    </div>
    <div class="title-container">
      <span class="header-title">Adnosaur Admin Dashboard</span>
    </div>
  </div>
  <div id="app">
    <nav class="main-nav">
      <router-link to="/" class="nav-link">Home Page</router-link> |
      <router-link to="/server-stats" class="nav-link">Server Stats</router-link> |
      <router-link to="/database-stats" class="nav-link">Database Stats</router-link> |
      <router-link to="/incorrect-products" class="nav-link">Incorrect Products</router-link> |
      <router-link to="/users" class="nav-link">Users</router-link> |
      <router-link to="/users-log" class="nav-link">Users Log</router-link> |
      <span v-if="checkAuthentication">
        <router-link to="/login" class="nav-link">Login</router-link> 
      </span>
      <span v-else>
        <router-link to="/logout" class="nav-link">Logout</router-link>
      </span>
    </nav>
    <RouterView /> 
  </div>
</template>

<script>
import axios from 'axios';
import checkAuth from './utility/checkAuthentication';

export default {
  data() {
    return {
      servers: null,
      databaseStats: null
    };
  },
  methods: {
    fetchServers() {
      axios.get(process.env.VUE_APP_API_URL + 'api/serverPerformance')
        .then(response => {
          this.servers = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the servers: ", error);
        });
    },
    fetchDatabaseStats() {
      axios.get(process.env.VUE_APP_API_URL + 'api/stats')
        .then(response => {
          this.databaseStats = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the database stats: ", error);
        });
    },
    checkAuthentcation() {
      return checkAuth();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Centers the content horizontally */
  padding: 10px;
  width: 100%;
  /* Ensures the header takes full width */
}

.logo-container {
  padding-right: 10px;
}

.logoImg {
  height: auto;
  /* Maintain aspect ratio */
  width: 100px;
  /* Adjust as needed */
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-title {
  font-size: 1.5em;
  line-height: 1;
  margin: 0;
}
.main-nav {
  background-color: #009879;
  text-align: center;
  padding: 10px 0;
}

.nav-link {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s;
}

.nav-link:hover, .router-link-exact-active {
  color: #00d7be;
  font-weight: bold;
}

</style>
