import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '@/components/HomePage.vue';
import ServerStatsPage from '@/components/ServerStats.vue';
import DatabaseStatsPage from '@/components/DatabaseStats.vue';
import LoginPage from '@/components/LoginPage.vue';
import IncorrectProducts from '@/components/IncorrectProducts.vue';
import UsersPage from '@/components/UsersPage.vue';
import UsersLog from '@/components/UsersLog.vue';

import checkAuth from '@/utility/checkAuthentication';

const routes = [
    { path: '/', component: HomePage, meta: { requiresAuth: true } },
    { path: '/server-stats', component: ServerStatsPage, meta: { requiresAuth: true } },
    { path: '/database-stats', component: DatabaseStatsPage, meta: { requiresAuth: true } },
    { path: '/incorrect-products', component: IncorrectProducts, meta: { requiresAuth: true } },
    { path: '/users', component: UsersPage, meta: { requiresAuth: true } },
    { path: '/users-log', component: UsersLog, meta: { requiresAuth: true } },
    { path: '/login', component: LoginPage }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await checkAuth();
    if (!isAuthenticated) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;