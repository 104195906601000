<template>
    <div class="login-container">
        <h1>Login</h1>
        <form @submit.prevent="login" class="login-form">
            <div class="form-group">
                <label for="username">Username:</label>
                <input type="text" id="username" v-model="username" required>
            </div>
            <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" v-model="password" required>
            </div>
            <button type="submit" class="login-btn">Login</button>
        </form>
        <div class="status-message">
            <p v-if="statusMessage" :class="{'success': statusCode === true, 'error': statusCode === false}">{{ statusMessage }}</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            username: '',
            password: '',
            statusMessage: '',
            statusCode: null,
            response: null
        };
    },
    methods: {
        login() {
            axios.post(process.env.VUE_APP_API_URL + 'api/login', {
                username: this.username,
                password: this.password
            },
            {
                withCredentials: true
            }            
            )
            .then(response => {
                this.statusMessage = 'Succesfully logged in! Redirecting...';
                this.statusCode = true;
                this.response = response;
                this.$router.push('/server-stats');
            })
            .catch(error => {
                this.statusMessage = 'There was an error logging in. Please try again.';
                this.statusCode = false;
                this.response = error;
            });
        }
    }
};
</script>

<style scoped>
.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

h1 {
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 5px;
}

input {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-btn {
  background-color: #009879;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.2s;
}

.login-btn:hover {
  background-color: #00796b;
  transform: translateY(-2px);
}

.login-btn:active {
  background-color: #005f50;
  transform: translateY(1px);
}

.status-message {
    font-weight: bold;
}

.status-message .success {
    color: rgb(30, 198, 30);
}

.status-message .error {
    color: rgb(255, 0, 0);
}

</style>
