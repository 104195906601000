<template>
    <div id="incorrect-products">
        <div class="tables-container">
            <table class="stats-table">
                <thead>
                    <tr>
                        <th colspan="6"><b>POSSIBLE INCORRECT PRODUCTS:</b></th>
                    </tr>
                    <tr>
                        <th>Product ID</th>
                        <th>Product Name</th>
                        <th>Link to product</th>
                        <th>Mark as correct</th>
                        <th>Mark as incorrect</th>
                        <th>Already marked as incorrect</th>
                    </tr>
                </thead>
                <tbody class="scrollable-tbody">
                    <!-- <tr v-for="adsStat in adsStats" :key="adsStat.ads_id">
                        <td>{{ adsStat.ads_id }}</td>
                        <td>{{ formatDate(adsStat.ads_datetime_checked) }}</td>
                        <td>{{ adsStat.ads_amount }}</td>
                        <td>{{ adsStat.ads_amount }}</td>
                        <td>{{ adsStat.ads_amount }}</td>
                    </tr> -->
                    <tr v-for="incorrectProduct in incorrectProducts" :key="incorrectProduct.incorrect_products_id">
                        <td>{{ incorrectProduct.incorrect_ali_product_id }}</td>
                        <td>{{ incorrectProduct.incorrect_store_product_title }}</td>
                        <td><a :href="'https://app.adnosaur.com/products/' + incorrectProduct.incorrect_ali_product_id" class="ip-a" target="_blank">Link</a></td>
                        <td>
                            <form @submit.prevent="correctBtn(incorrectProduct.incorrect_ali_product_id)">
                                <button class="ip-btn" type="submit" >
                                    <img src="../assets/checkbox.svg" alt="Checkbox" class="correct-btn" />
                                </button>
                            </form>
                        </td>
                        <td>
                            <form @submit.prevent="incorrectBtn(incorrectProduct.incorrect_ali_product_id)">
                                <button class="ip-btn" type="submit">
                                    <img src="../assets/cross.svg" alt="Cross" class="incorrect-btn" />
                                </button>
                            </form>
                        </td>
                        <td>{{ incorrectProduct.incorrect_product_marked_as_incorrect }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';



export default {
    data() {
        return {
            incorrectProducts: null,
            loading: false
        };
    },
    async created() {
        this.fetchData();
        // this.getLatestResults();
    },
    methods: {
        correctBtn(productId) {
            console.log('Correct button clicked', productId);
            this.loading = true;
            axios.post(`${process.env.VUE_APP_API_URL}api/database/mark-as-correct/${productId}`)
                .then(response => {
                    this.incorrectProducts = response.data.incorrect_products;
                    this.loading = false;
                    this.fetchData();
                })
                .catch(error => {
                    console.error('Error updating stats: ' + error);
                });
            // todo: delete product from incorrect-products table
        },
        incorrectBtn(productId) {
            this.loading = true;
            axios.post(`${process.env.VUE_APP_API_URL}api/database/mark-as-incorrect/${productId}`)
                .then(response => {
                    this.incorrectProducts = response.data.incorrect_products;
                    this.loading = false;
                    this.fetchData();
                })
                .catch(error => {
                    console.error('Error updating stats: ' + error);
                });
        },
        async fetchData() {
            this.loading = true;
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'api/database/retrieve-incorrect-products');
                this.incorrectProducts = response.data.incorrect_products;
                console.log('Incorrect products:', this.incorrectProducts);
                this.loading = false;
            } catch (error) {
                console.error('Error fetching stats: ' + error);
            }
        },
    }
};
</script>

<style scoped>
#database-stats {
    border-radius: 10px;
    background-color: #f9f9f9;
    /* Light background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    padding: 15px;
    margin: 20px 0;
    font-family: Arial, sans-serif;
}

#database-stats h1 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

#database-stats p {
    font-size: 1em;
    color: #555;
    margin: 5px 0;
    line-height: 1.4;
}

.loading {
    text-align: center;
    font-style: italic;
}

.stats-table {
    width: 80%;
    min-width: 20%;
    border-collapse: collapse;
    margin: 10px;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: 10%;
    margin-right: 10%;
}

.scrollable-tbody {
    display: block;
    max-height: 300px;
    /* Adjust this value based on the height of 10 rows */
    overflow-y: auto;
}


.stats-table thead,
.stats-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* Optional, for equal width columns */
}

.stats-table th,
.stats-table td {
    padding: 12px 15px;
}

.stats-table th {
    background-color: #009879;
    color: #ffffff;
    text-align: center;
}

.stats-table tr {
    border-bottom: 1px solid #dddddd;
}

.stats-table tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.stats-table tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.stats-table tr:hover {
    background-color: #f1f1f1;
}

.tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 600px) {

    /* Adjust breakpoint as needed */
    .tables-container {
        flex-direction: column;
    }
}

.update-db-btn {
    background-color: #009879;
    /* Same color as the table headers for consistency */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.2s;
}

.update-db-btn:hover {
    background-color: #00796b;
    /* Slightly darker shade on hover */
    transform: translateY(-2px);
    /* Slight lift effect on hover */
}

.update-db-btn:active {
    background-color: #005f50;
    /* Even darker for the active state */
    transform: translateY(1px);
    /* Resetting position on active */
}

.correct-btn, .incorrect-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ip-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.ip-a {
    color: #009879;
    text-decoration: none;
}

</style>