<template>
  <div>
    <h1>DigitalOcean Performance Data</h1>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <div id="server-container">
        <div class="server-tile" v-for="server in servers" :key="server.id"
        :class="{ 'active': server.status === 'active', 'inactive': server.status === 'inactive' || server.status === 'off' }">
          <h3>{{ server.name }}</h3>
          <hr class="separator">
          <div class="server-stats">
            <p><strong>Memory:</strong> {{ server.memory }} MB</p>
            <p><strong>CPU:</strong> {{ server.vcpus }} Cores</p>
          </div>
          <div class="server-stats">
            <p><strong>Disk:</strong> {{ server.disk }} GB</p>
            <p><strong>Region:</strong> {{ server.region }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import parseJson from '@/helpers/parseJson.js';

export default {
  data() {
    return {
      servers: null,
      loading: false
    };
  },
  async created() {
    this.loading = true;
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + 'api/serverPerformance');
      this.servers = parseJson(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style>
#server-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Horizontally center the items */
  align-items: center; /* Vertically center the items */
  padding: 20px;
}

.server-tile {
  flex: 0 1 calc(33.333% - 20px);
  border-radius: 10px;
  padding: 15px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  height: 200px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.server-tile.active {
  background-color: #4CAF50;
  border: 2px solid #388E3C;
}

.server-tile.inactive, .server-tile.off {
  background-color: #f44336;
  border: 2px solid #d32f2f;
}

.server-tile:hover {
  transform: translateY(-5px);
}

.server-tile h3 {
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 90%;
}

.separator {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

.server-stats {
  display: flex;
  justify-content: space-between;
}

.server-tile p {
  margin: 5px 0;
  flex-basis: 48%;
}

</style>