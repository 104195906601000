<template>
    <div id="database-stats">
        <h1>Database Stats</h1>
        <div>
            <form @submit.prevent="updateDb">
                <button type="submit" class="update-db-btn">Update Stats</button>
            </form>
        </div>
        <div v-if="loading" class="loading">Loading...</div>
        <!-- <div v-else>
            <p>Total Ads: {{ ads_count }}</p>
            <p>Total Ad Pages: {{ ad_pages_count }}</p>
            <p>Total Stores: {{ stores_count }} (of which {{ active_stores_count }} Active)</p>
            <p>Total Store Products: {{ store_products_count }}</p>
            <p>Total Ali Products: {{ ali_products_count }}</p>
        </div> -->
        <div v-else>
            <!-- <div>
                <h1>Current Stats: </h1>
                <p>Total Ads: {{ currentAdsStats }}</p>
                <p>Total Ad Pages: {{ currentAdsPagesStats }}</p>
                <p>Total Stores: {{ currentStoresStats }} </p>
                <p>Active Stores {{ currentActiveStoresStats }} </p>
                <p>Store Products: {{ currentStoreProductsStats }}</p>
                <p>Ali Products: {{ currentAliProductsStats }}</p>

            </div> -->
            <div class="tables-container">
                <table class="stats-table">
                    <thead>
                        <tr>
                            <th colspan="3"><b>AD STATS:</b></th>
                        </tr>
                        <tr>
                            <th>Ad Id</th>
                            <th>Ad Last Checked</th>
                            <th>Ad Count</th>
                        </tr>
                    </thead>
                    <tbody class="scrollable-tbody">
                        <tr v-for="adsStat in adsStats" :key="adsStat.ads_id">
                            <td>{{ adsStat.ads_id }}</td>
                            <td>{{ formatDate(adsStat.ads_datetime_checked) }}</td>
                            <td>{{ adsStat.ads_amount }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="stats-table">
                    <thead>
                        <tr>
                            <th colspan="3"><b>AD PAGE STATS:</b></th>
                        </tr>
                        <tr>
                            <th>Ad Page Id</th>
                            <th>Ad Page Last Checked</th>
                            <th>Ad Page Count</th>
                        </tr>
                    </thead>
                    <tbody class="scrollable-tbody">
                        <tr v-for="adsPagesStat in adsPagesStats" :key="adsPagesStat.ads_pages_id">
                            <td>{{ adsPagesStat.ads_pages_id }}</td>
                            <td>{{ formatDate(adsPagesStat.ads_pages_datetime_checked) }}</td>
                            <td>{{ adsPagesStat.ads_pages_amount }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="stats-table">
                    <thead>
                        <tr>
                            <th colspan="5"><b>STORE STATS:</b></th>
                        </tr>
                        <tr>
                            <th>Store Id</th>
                            <th>Store Last Checked</th>
                            <th>Store Count</th>
                            <th>Store Active Count</th>
                            <th>Store Actual Active</th>
                        </tr>
                    </thead>
                    <tbody class="scrollable-tbody">
                        <tr v-for="storesStat in storesStats" :key="storesStat.stores_id">
                            <td>{{ storesStat.stores_id }}</td>
                            <td>{{ formatDate(storesStat.stores_datetime_checked) }}</td>
                            <td>{{ storesStat.stores_amount }}</td>
                            <td>{{ storesStat.stores_active_amount }}</td>
                            <td>{{ storesStat.stores_actual_active_amount }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="stats-table">
                    <thead>
                        <tr>
                            <th colspan="3"><b>STORE PRODUCT STATS:</b></th>
                        </tr>
                        <tr>
                            <th>Store Product Id</th>
                            <th>Store Product Last Checked</th>
                            <th>Store Product Count</th>
                        </tr>
                    </thead>
                    <tbody class="scrollable-tbody">
                        <tr v-for="storeProductsStat in storeProductsStats" :key="storeProductsStat.store_products_id">
                            <td>{{ storeProductsStat.store_products_id }}</td>
                            <td>{{ formatDate(storeProductsStat.store_products_datetime_checked) }}</td>
                            <td>{{ storeProductsStat.store_products_amount }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="stats-table">
                    <thead>
                        <tr>
                            <th colspan="3"><b>ALI PRODUCT STATS:</b></th>
                        </tr>
                        <tr>
                            <th>Ali Product Id</th>
                            <th>Ali Product Last Checked</th>
                            <th>Ali Product Count</th>
                        </tr>
                    </thead>
                    <tbody class="scrollable-tbody">
                        <tr v-for="aliProductsStat in aliProductsStats" :key="aliProductsStat.ali_products_id">
                            <td>{{ aliProductsStat.ali_products_id }}</td>
                            <td>{{ formatDate(aliProductsStat.ali_products_datetime_checked) }}</td>
                            <td>{{ aliProductsStat.ali_products_amount || abbr }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';



export default {
    data() {
        return {
            databaseStats: null,
            adsStats: null,
            adsPagesStats: null,
            storesStats: null,
            storeProductsStats: null,
            aliProductsStats: null,
            currentAdsStats: null,
            currentAdsPagesStats: null,
            currentStoresStats: null,
            currentActiveStoresStats: null,
            currentStoreProductsStats: null,
            currentAliProductsStats: null,
            loading: false
        };
    },
    async created() {
        this.fetchData();
        // this.getLatestResults();
    },
    methods: {
        formatDate(datetime) {
            const dateObj = new Date(datetime);
            const day = dateObj.getDate().toString().padStart(2, '0');
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
            const year = dateObj.getFullYear();
            const hours = dateObj.getHours().toString().padStart(2, '0');
            const minutes = dateObj.getMinutes().toString().padStart(2, '0');

            return `${day}/${month}/${year}, ${hours}:${minutes}`;
        },
        updateDb() {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'api/database/update-stats')
                .then(response => {
                    this.databaseStats = response.data;
                    this.adsStats = this.databaseStats.ads_stats;
                    this.adsPagesStats = this.databaseStats.ad_pages_stats;
                    this.storesStats = this.databaseStats.stores_stats;
                    this.storeProductsStats = this.databaseStats.store_products_stats;
                    this.aliProductsStats = this.databaseStats.ali_products_stats;
                    this.loading = false;
                    this.fetchData();
                })
                .catch(error => {
                    console.error('Error updating stats: ' + error);
                });
        }, 
        async fetchData() {
            this.loading = true;
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'api/database/retrieve-stats');
                this.databaseStats = response.data;
                this.adsStats = this.databaseStats.ads_stats;
                this.adsPagesStats = this.databaseStats.ad_pages_stats;
                this.storesStats = this.databaseStats.stores_stats;
                this.storeProductsStats = this.databaseStats.store_products_stats;
                this.aliProductsStats = this.databaseStats.ali_products_stats;
                this.loading = false;
            } catch (error) {
                console.error('Error fetching stats: ' + error);
            }
        },
        // getLatestResults() {
        //     console.log('ads stats: ', this.adsStats);
        //     this.currentAdsStats = this.adsStats[this.adsStats.length - 1].ads_amount;
        //     this.currentAdsPagesStats = this.adsPagesStats[this.adsPagesStats.length - 1].ads_pages_amount;
        //     this.currentStoresStats = this.storesStats[this.storesStats.length - 1].stores_amount;
        //     this.currentActiveStoresStats = this.storesStats[this.storesStats.length - 1].stores_active_amount;
        //     this.currentStoreProductsStats = this.storeProductsStats[this.storeProductsStats.length - 1].store_products_amount;
        //     this.currentAliProductsStats = this.aliProductsStats[this.aliProductsStats.length - 1].ali_products_amount;
        // }
    }, 
    filters: {
        abbr: function(num) { 
            if (String(num).length < 7) { 
                return Math.floor(num/1000) + 'K'; 
            } else { 
                return Math.floor(num/1000000) + 'M'; 
            } 
        } 
    }
};
</script>

<style scoped>
#database-stats {
    border-radius: 10px;
    background-color: #f9f9f9;
    /* Light background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    padding: 15px;
    margin: 20px 0;
    font-family: Arial, sans-serif;
}

#database-stats h1 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

#database-stats p {
    font-size: 1em;
    color: #555;
    margin: 5px 0;
    line-height: 1.4;
}

.loading {
    text-align: center;
    font-style: italic;
}

.stats-table {
    width: 450px;
    min-width: 20%;
    border-collapse: collapse;
    margin: 10px;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.scrollable-tbody {
    display: block;
    max-height: 300px;
    /* Adjust this value based on the height of 10 rows */
    overflow-y: auto;
}


.stats-table thead,
.stats-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* Optional, for equal width columns */
}

.stats-table th,
.stats-table td {
    padding: 12px 15px;
}

.stats-table th {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.stats-table tr {
    border-bottom: 1px solid #dddddd;
}

.stats-table tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.stats-table tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.stats-table tr:hover {
    background-color: #f1f1f1;
}

.tables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (max-width: 600px) {

    /* Adjust breakpoint as needed */
    .tables-container {
        flex-direction: column;
    }
}

.update-db-btn {
  background-color: #009879; /* Same color as the table headers for consistency */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.2s;
}

.update-db-btn:hover {
  background-color: #00796b; /* Slightly darker shade on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.update-db-btn:active {
  background-color: #005f50; /* Even darker for the active state */
  transform: translateY(1px); /* Resetting position on active */
}
</style> 