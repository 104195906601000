<template>
    <div class="home">
        <h1>Welcome to the Homepage!</h1>
        <p>This is the homepage of your admin dashboard.</p>
    </div>
</template>

<script>
export default {
    name: 'HomePage',
}
</script>

<style scoped>
.home {
    text-align: center;
    margin-top: 50px;
}
</style>
