export default function parseJson(jsonVariable){
    let droplets = jsonVariable.droplets;
    let dropletInfo = [];
    for (let i = 0; i < droplets.length; i++) {
        let droplet = droplets[i];
        let dropletName = droplet.name;
        let dropletStatus = droplet.status;
        let dropletMemory = droplet.memory;
        let dropletVcpus = droplet.vcpus;
        let dropletDisk = droplet.disk;
        let dropletRegion = droplet.region.name;
        let dropletInfoDict = {
            "name": dropletName,
            "status": dropletStatus,
            "memory": dropletMemory,
            "vcpus": dropletVcpus,
            "disk": dropletDisk,
            "region": dropletRegion
        }
        dropletInfo.push(dropletInfoDict);
    }
    return dropletInfo;
}