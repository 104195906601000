import axios from 'axios';

async function checkAuth() {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + 'api/check', { withCredentials: true });
    return response.data.isAuthenticated;
  } catch (error) {
    console.error('Error checking authentication:', error);
    return false;
  }
}

export default checkAuth;