<template>
    <div id="user-activity-logs">
      <h1>User Activity Logs</h1>
      <div class="filter-container">
        <div class="fancy-dropdown">
          <input
            type="text"
            v-model="searchTerm"
            @input="searchUsers"
            @focus="showDropdown = true"
            placeholder="Search and select a user..."
          />
          <div class="dropdown-list" v-if="showDropdown">
            <div
              v-for="user in filteredUsers"
              :key="user.user_unique_id"
              @click="selectUser(user.user_email)"
              class="dropdown-item"
            >
              {{ user.user_email }}
            </div>
          </div>
        </div>
      </div>
  
      <div class="table-container" v-if="selectedUser">
        <h2>Activity Logs for {{ selectedUser }}</h2>
        <table class="fancy-table">
          <thead>
            <tr>
              <th>Page Visited</th>
              <th>Credits Deducted</th>
              <th>IP Address</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in activityLogs" :key="log.id">
              <td><a :href="createUrl(log.page_visited)" target="_blank">{{ log.page_visited }}</a></td>
              <td>{{ log.credits_deducted }}</td>
              <td>{{ log.ip_address }}</td>
              <td>{{ new Date(log.timestamp).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        users: [],
        filteredUsers: [],
        selectedUser: '',
        searchTerm: '',
        activityLogs: [],
        showDropdown: false,
      };
    },
    async created() {
      await this.fetchUsersWithLogs();
    },
    methods: {
      async fetchUsersWithLogs() {
        try {
          // Assuming you create a new endpoint that returns only users with log entries
          const response = await axios.get(`${process.env.VUE_APP_API_URL}api/database/users-with-logs`);
          this.users = response.data.users;
          this.filteredUsers = this.users;
        } catch (error) {
          console.error('Error fetching users with logs:', error);
        }
      },
      searchUsers() {
        this.filteredUsers = this.users.filter(user => 
          user.user_email.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        this.showDropdown = true;
      },
      selectUser(email) {
        this.selectedUser = email;
        this.searchTerm = email;
        this.showDropdown = false;
        this.fetchUserActivityLogs();
      },
      async fetchUserActivityLogs() {
        if (!this.selectedUser) return;
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}api/database/user-activity-logs`, {
            params: { user_email: this.selectedUser }
          });
          this.activityLogs = response.data.logs;
        } catch (error) {
          console.error('Error fetching user activity logs:', error);
        }
      },
      createUrl(pageVisited) {
        return `https://app.adnosaur.com${pageVisited}`;
      },
    },
  };
  </script>
  
  <style scoped>
  #user-activity-logs {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1, h2 {
    color: #333;
    text-align: center;
  }
  
  .filter-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .fancy-dropdown {
    position: relative;
    width: 300px;
  }
  
  .fancy-dropdown input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .table-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .fancy-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .fancy-table th, .fancy-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .fancy-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    color: #333;
  }
  
  .fancy-table tr:last-child td {
    border-bottom: none;
  }
  
  .fancy-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .fancy-table tr:hover {
    background-color: #f0f0f0;
  }
  </style>